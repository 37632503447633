/** @format */

import React, { useEffect, useRef, useState } from "react";
import Slider from "./Slider";
import { Link } from "react-router-dom";
import ApiService from "../../services/apiService";
import { toast } from "react-toastify";
import { Image_PATH } from "../../config/constant";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export default function Home() {
  const [Destination, setDestination] = useState([]);
  const [testimonial, setTestimonial] = useState([]);
  const [banners, setBanners] = useState([]);
  const [whyBook, setWhyBook] = useState([]);
  const [settingData, setSettingsData] = useState({});
  const [Themes, setThemes] = useState([]);
  const [popularPackage, setPopularPackage] = useState([]);
  const [page, setPage] = useState({});
  const [metaTags, setMetaTags] = useState("");
  useEffect(() => {
    getTestimonialList();
    getBannerList();
    getWhyBookList();
    getSettings();
    getDestinationList();
    getThemesList();
    getPopularPackageList();
    getMeta();
  }, []);

  const options = {
    items: 4,
    loop: true,
    margin: 10,
    autoplay: true,
    dots: true,
    nav: true,
    autoplayTimeout: 3000,
    responsive: {
      0: {
        items: 1, // 1 item on smaller screens
      },
      600: {
        items: 1, // 1 item on medium screens
      },
      1000: {
        items: 4, // 1 item on larger screens
      },
    },
  };

  const options1 = {
    items: 1, // Only show one item at a time
    loop: true, // Allow looping
    margin: 10, // Add some margin between items
    responsive: {
      0: {
        items: 1, // 1 item on smaller screens
      },
      600: {
        items: 1, // 1 item on medium screens
      },
      1000: {
        items: 1, // 1 item on larger screens
      },
    },
    nav: true, // Enable navigation buttons
    dots: true, // Enable dots navigation
  };

  const getMeta = async () => {
    ApiService.post("page/get-data", {
      page: 1,
      limit: 10000,
      page_name: "home",
    })
      .then((data) => {
        if (data?.Pages) {
          setPage(data?.Pages?.[0]);
          setMetaTags(data?.Pages?.[0]?.header);
        } else {
          setPage([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getPopularPackageList = async () => {
    ApiService.post("package/get-data", {
      page: 1,
      limit: 15,
      type: "india",
      popular: "1",
    })
      .then((data) => {
        if (data?.Packages) {
          setPopularPackage(data.Packages);
        } else {
          setPopularPackage([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getThemesList = async () => {
    ApiService.post("themes/get-data-type", {
      page: 1,
      limit: 1000,
      home: "1",
      type: "india",
    })
      .then((data) => {
        if (data?.Themess) {
          setThemes(data.Themess);
        } else {
          setThemes([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getDestinationList = async () => {
    ApiService.post("destination/get-data-type", {
      page: 1,
      limit: 10000,
      home: "1",
    })
      .then((data) => {
        if (data?.Destinations) {
          setDestination(data.Destinations);
        } else {
          setDestination([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getTestimonialList = async () => {
    ApiService.post("testimonial/get-data", { page: 1, limit: 50, status: "1" })
      .then((data) => {
        if (data?.testimonials) {
          console.log(data?.testimonials);
          setTestimonial(data.testimonials);
        } else {
          setTestimonial([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getBannerList = async () => {
    ApiService.post("banner/get-data", { page: 1, limit: 10000, status: "1" })
      .then((data) => {
        if (data?.Banners) {
          setBanners(data.Banners);
        } else {
          setBanners([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getWhyBookList = async () => {
    ApiService.post("whybook/get-data", { page: 1, limit: 4, status: "1" })
      .then((data) => {
        if (data?.WhyBooks) {
          setWhyBook(data.WhyBooks);
        } else {
          setWhyBook([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getSettings = async () => {
    ApiService.get("settings/1")
      .then((data) => {
        if (data?.data) {
          setSettingsData(data.data);
        } else {
          setSettingsData({});
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  useEffect(() => {
    // If metaTags is not empty, inject it into the head
    if (metaTags) {
      const head = document.head;
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = metaTags;
      // Inject all meta tags into the head
      Array.from(tempDiv.children).forEach((child) => {
        head.prepend(child);
      });

      const body = document.body;
      const tempDivbody = document.createElement("div");
      tempDivbody.innerHTML = page?.footer;
      // Inject all meta tags into the head
      Array.from(tempDivbody.children).forEach((child) => {
        body.append(child);
      });
    }
  }, [metaTags]);

  return (
    <>
      <Slider destination={Destination} themes={Themes} />
      <section className="destination">
        <div className="container-fluid">
          <div className="title">
            <h2>Discover The India With Us</h2>
            <p>
              Whatever you're looking for places for a vacation. We are here to
            </p>
          </div>
          <div className="destionation-boxes mt-5">
            <div className="row">
              {Destination &&
                Destination?.length &&
                Destination?.filter((dt) => dt?.type == "india")?.map(
                  (d, k) => {
                    return (
                      <div className="col-lg-2 col-md-4 col-6 mb-5" key={k}>
                        <div className="destionation-box">
                          <Link to={`package-list/india/${d?.url}`}>
                            <div className="destination-img">
                              <img
                                src={`${Image_PATH}destination/${d?.image_url}`}
                                alt=""
                                width="100%"
                              />
                            </div>
                            <div className="destionation-text">
                              <h5> {d?.name} </h5>
                              <p>{d?.packages?.[0]?.count || 0} Tour Package</p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  }
                )}
             
            </div>
            {/* <div className="view-more-btn">
              <Link className="btn btn-view" to={`/india-tour`}>
                View More <i className="fa fa-arrow-right" />
              </Link>
            </div> */}
          </div>
        </div>
      </section>
      {/* destination area start  */}
      {/*international destination area start  */}
      <section className="destination-international">
        <div className="container-fluid">
          <div className="title">
            <h2>Discover The World With Us</h2>
            <p>
              Whatever you're looking for places for a vacation. We are here to
            </p>
          </div>
          <div className="intern-boxes mt-5">
            <div className="row">
              {Destination &&
                Destination?.length &&
                Destination?.filter((dt) => dt?.type === "international")?.map(
                  (d, k) => {
                    return (
                      <div className="col-lg-2 col-md-4 col-6 mb-5" key={k}>
                        <div className="intern-box">
                          <Link
                            to={`package-list/international/${d?.url}`}
                          >
                            <div className="destination-img">
                              <img
                                src={`${Image_PATH}destination/${d?.image_url}`}
                                alt=""
                                width="100%"
                              />
                            </div>
                            <div className="inter-text">
                              <h5> {d?.name} </h5>
                              <p>{d?.packages?.[0]?.count || 0} Tour Package</p>
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  }
                )}
              {/* <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="intern-box">
              <a href="#">
                <div className="destination-img">
                  <img
                    src="assets/images/internatioanl-destination/2.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="inter-text">
                  <h5> Singapore </h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="intern-box">
              <a href="#">
                <div className="destination-img">
                  <img
                    src="assets/images/internatioanl-destination/3.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="inter-text">
                  <h5> Bali </h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="intern-box">
              <a href="#">
                <div className="destination-img">
                  <img
                    src="assets/images/internatioanl-destination/4.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="inter-text">
                  <h5> Dubai </h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="intern-box">
              <a href="#">
                <div className="destination-img">
                  <img
                    src="assets/images/internatioanl-destination/5.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="inter-text">
                  <h5> Maldives</h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6 mb-5">
            <div className="intern-box">
              <a href="#">
                <div className="destination-img">
                  <img
                    src="assets/images/internatioanl-destination/6.jpg"
                    alt=""
                    width="100%"
                  />
                </div>
                <div className="inter-text">
                  <h5> Malaysia</h5>
                  <p>15 Tour Package</p>
                </div>
              </a>
            </div>
          </div> */}
            </div>
            <div className="destination-banner mt-3">
              <div className="row">
                {banners &&
                  banners?.length > 0 &&
                  banners
                    ?.filter((b) => b.type === "1")
                    ?.map((tm,k) => {
                      return (
                        <div className="col-lg-3 col-md-6 col-6 mb-3" key={k}>
                          { tm?.url ? <Link to={`${tm?.url}`}>
                          <div className="destionation-banner">
                            <img
                              src={`${Image_PATH}Banner/${tm?.image_url}`}
                              alt={tm?.alt_image}
                              width="100%"
                            />
                          </div>
                          </Link> : <div className="destionation-banner">
                            <img
                              src={`${Image_PATH}Banner/${tm?.image_url}`}
                              alt={tm?.alt_image}
                              width="100%"
                            />
                          </div> }
                        </div>
                      );
                    })}
                {/* <div className="col-lg-3 col-md-6 col-6 mb-3">
              <div className="destionation-banner">
                <img
                  src="assets/images/destionation-banners/2.PNG"
                  alt=""
                  width="100%"
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6 mb-3">
              <div className="destionation-banner">
                <img
                  src="assets/images/destionation-banners/1.PNG"
                  alt=""
                  width="100%"
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-6 mb-3">
              <div className="destionation-banner">
                <img
                  src="assets/images/destionation-banners/2.PNG"
                  alt=""
                  width="100%"
                />
              </div>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* destination area start  */}
      {/* special travels area start  */}
      <section className="special-travels pt-5 pb-5">
        <div className="container-fluid">
          <div className="title">
            <h2>Our Travel Specialization</h2>
            <p>
              Whatever you're looking for places for a vacation. We are here to
            </p>
          </div>
          <div className="row mt-5">
            <div className="col-lg-8 col-md-12 col-12">
              <div className="row">
                {Themes &&
                  Themes?.length > 0 &&
                  Themes?.map((t, k) => {
                    return (
                      <div className="col-lg-4 col-md-6 col-12 mb-3">
                        <div className="special-packages">
                          <Link to={`package-list-by-themes/india/${t?.url}`}>
                            <div className="special-images">
                              <img
                                src={`${Image_PATH}Themes/${t?.image_url}`}
                                alt=""
                                width="100%"
                              />
                            </div>
                            <div className="special-text">
                              <div className="special-text-name">
                                <h6>{t?.name}</h6>
                                <p>{t?.packages?.[0]?.count} Tour Packages</p>
                              </div>
                              <div className="special-text-review">
                                <span>
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                </span>
                                <p>62 Review</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    );
                  })}
                {/* <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/1.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Honeymoon Tour</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/2.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Leasure</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/3.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Trekking</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/4.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Adventure</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/5.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Hill Station</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 mb-3">
              <div className="special-packages">
                <a href="#">
                  <div className="special-images">
                    <img
                      src="assets/images/special-packages/6.jpg"
                      alt=""
                      width="100%"
                    />
                  </div>
                  <div className="special-text">
                    <div className="special-text-name">
                      <h6>Yoga</h6>
                      <p>15 Tour Packages</p>
                    </div>
                    <div className="special-text-review">
                      <span>
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                        <i className="fa fa-star" />
                      </span>
                      <p>62 Review</p>
                    </div>
                  </div>
                </a>
              </div>
            </div> */}
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="special-banner">
                <div className="slider">
                {banners &&
                      banners?.length > 0 &&
                      banners
                        ?.filter((b) => b.type === "2")?.length>0 && (
                  <OwlCarousel
                  {...options1}
                    className="owl-carousel owl-theme"
                    
                  >
                    {banners &&
                      banners?.length > 0 &&
                      banners
                        ?.filter((b) => b.type === "2")
                        ?.map((tm,k) => {
                          return (
                            <div className="item" key={k}>
                              { tm?.url ?  <Link to={`${tm?.url}`}>
                              <img
                                src={`${Image_PATH}Banner/${tm?.image_url}`}
                                alt={tm?.alt_image}
                                width="100%"
                              />
                              </Link> : <img
                                src={`${Image_PATH}Banner/${tm?.image_url}`}
                                alt={tm?.alt_image}
                                width="100%"
                              /> }
                            </div>
                          );
                        })}
                    {/* <div className="item">
                  <img src="assets/images/b-2.jpg" alt="" width="100%" />
                </div>
                <div className="item">
                  <img src="assets/images/b-3.jpg" alt="" width="100%" />
                </div> */}
                  </OwlCarousel>
                        )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* special travels area end  */}
      {/* popular tour area start  */}
      <section className="popular-tour pt-5 pb-5">
        <div className="container-fluid">
          <div className="title">
            <h2>Our Most Popular Tours</h2>
            <p>
              Whatever you're looking for places for a vacation. We are here to
            </p>
          </div>
          <div className="row pt-5">
            {popularPackage &&
              popularPackage?.length > 0 &&
              popularPackage?.map((p, k) => {
                return (
                  <div className="col-lg-4 col-12 mb-3" key={k}>
                    <Link to={`package-details/${p?.url}`}>
                      <div className="popular-packages py-2 ">
                        <div className="row">
                          <div className="col-lg-12 col-md-6 col-12">
                            <div className="row popular-packages-box m-1">
                              <div className="col-4">
                                <div className="popular-package-img">
                                  <img
                                    src={`${Image_PATH}Package/${p?.image_url}`}
                                    alt=""
                                    width="100%"
                                  />
                                </div>
                              </div>
                              <div className="col-8">
                                <div className="popular-package-text">
                                  <h5>{p?.title}</h5>
                                  <span>{p?.duration}</span>
                                  <p>{p?.destination_cover}</p>
                                  <h4>
                                    <i className="fa fa-inr" />{" "}
                                    {p?.offer_price || 0}/-{" "}
                                    <span>Per Person</span>
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
      {/* popular tour area end  */}
      {/* Why Book Are Start  */}
      <section className="why-book py-5">
        <div className="container-fluid">
          <div className="title">
            <h2>Why Book With Us</h2>
            <p>
              Whatever you're looking for places for a vacation. We are here to
            </p>
          </div>
          <div className="row mt-5">
            <div className="col-lg-6">
              <div className="features">
                <div className="row">
                  {whyBook &&
                    whyBook?.length > 0 &&
                    whyBook?.map((wb) => {
                      return (
                        <div className="col-md-6 mb-3">
                          <div className="why-book-box box-1" id="box-1">
                            <div className="why-icon">
                              <img
                                src={`${Image_PATH}Whybook/${wb?.image_url}`}
                                alt=""
                              />
                            </div>
                            <div className="why-text">
                              <h4>{wb?.title}</h4>
                              <p>{wb?.description}</p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {/* <div className="col-md-6 mb-3">
                <div className="why-book-box box-2" id="box-2">
                  <div className="why-icon">
                    <img src="assets/images/icons/compititive-price.png" alt="" />
                  </div>
                  <div className="why-text">
                    <h4>Competitive Pricing</h4>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="why-book-box" id="box-3">
                  <div className="why-icon">
                    <img src="assets/images/icons/calendar.png" alt="" />
                  </div>
                  <div className="why-text">
                    <h4>Fast Booking</h4>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div className="why-book-box" id="box-4">
                  <div className="why-icon">
                    <img src="assets/images/icons/support.png" alt="" />
                  </div>
                  <div className="why-text">
                    <h4>Best Support 24/7</h4>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.{" "}
                    </p>
                  </div>
                </div>
              </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="why-details">
                <span>Our Experience</span>
                <h3>{settingData?.exp_title}</h3>
                <p className="pb-3"><div
                    dangerouslySetInnerHTML={{
                      __html: settingData?.exp_description,
                    }}
                  /></p>
                <hr />
              </div>
              <div className="why-features row py-3">
                <div className="features-box col-4">
                  <div className="features-box-icon">
                    <img
                      src="assets/images/icons/traveler.png"
                      alt=""
                      width="45px"
                    />
                  </div>
                  <div className="features-box-name pl-2">
                    <h4>{settingData?.happy_travellers}</h4>
                    <span>Happy Traveler</span>
                  </div>
                </div>
                <div className="features-box col-4" id="features-box-border">
                  <div className="features-box-icon">
                    <img
                      src="assets/images/icons/loyalty.png"
                      alt=""
                      width="45px"
                    />
                  </div>
                  <div className="features-box-name pl-2">
                    <h4>{settingData?.tour_success}</h4>
                    <span>Tours Success</span>
                  </div>
                </div>
                <div className="features-box col-4">
                  <div className="features-box-icon">
                    <img
                      src="assets/images/icons/experience.png"
                      alt=""
                      width="45px"
                    />
                  </div>
                  <div className="features-box-name pl-2">
                    <h4>{settingData?.positive_review}</h4>
                    <span>Positives Review </span>
                  </div>
                </div>
              </div>
              <hr />
              <div className="last-line">
                <p className="text-center">
                  <b>Excellent!</b>{" "}
                  <span>
                    {" "}
                    <i className="fa fa-circle" />{" "}
                    <i className="fa fa-circle" />{" "}
                    <i className="fa fa-circle" />{" "}
                    <i className="fa fa-circle" />
                    <i className="fa fa-circle-o" />{" "}
                  </span>{" "}
                  {settingData?.review_text}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Why Book Are Start  */}
      {/* testimonial section start here  */}
      <section className="testimonials py-5">
        <div className="container-fluid">
          <div className="title">
            <h2>Customers Feedback</h2>
            <p>
              Whatever you're looking for places for a vacation. We are here to
            </p>
          </div>
          <div className="slider mt-5">
            {testimonial && testimonial?.length > 0 && (
              <OwlCarousel
              {...options}
                className="owl-carousel owl-theme"
                id="owl-testimonial"
              >
                {testimonial &&
                  testimonial?.length > 0 &&
                  testimonial?.map((tm, k) => {
                    return (
                      <div className="item" key={k}>
                        <div className="review-box">
                          <div className="client-img">
                            <div className="user-img">
                              <div className="user-icon">
                                <img
                                  src={`${Image_PATH}testimonial/${tm?.image_url}`}
                                  alt=""
                                  style={{ width: 60 }}
                                />
                              </div>
                              <div className="user-name">
                                <h5>{tm?.name}</h5>
                                <span>{tm?.designation}</span>
                              </div>
                            </div>
                            <div className="stars py-2">
                              {tm?.star && +tm?.star === 1 && (
                                <i className="fa fa-star" />
                              )}
                              {tm?.star && +tm?.star === 2 && (
                                <>
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />{" "}
                                </>
                              )}
                              {tm?.star && +tm?.star === 3 && (
                                <>
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                </>
                              )}
                              {tm?.star && +tm?.star === 4 && (
                                <>
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                </>
                              )}
                              {tm?.star && +tm?.star === 5 && (
                                <>
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                </>
                              )}
                              {/* <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" /> */}
                            </div>
                          </div>
                          <div className="review-para pt-2">
                            <p>{tm?.content}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {/* <div className="item">
            <div className="review-box">
              <div className="client-img">
                <div className="user-img">
                  <div className="user-icon">
                    <img
                      src="assets/images/icons/users.jpg"
                      alt=""
                      style={{ width: 60 }}
                    />
                  </div>
                  <div className="user-name">
                    <h5>Ethan Mitchell</h5>
                    <span>Students</span>
                  </div>
                </div>
                <div className="stars py-2">
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                </div>
              </div>
              <div className="review-para pt-2">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                  velit officia ratione ullam unde odio, sed optio itaque iste
                  magnam blanditiis dicta illo eum.
                </p>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="review-box">
              <div className="client-img">
                <div className="user-img">
                  <div className="user-icon">
                    <img
                      src="assets/images/icons/users.jpg"
                      alt=""
                      style={{ width: 60 }}
                    />
                  </div>
                  <div className="user-name">
                    <h5>Ethan Mitchell</h5>
                    <span>Students</span>
                  </div>
                </div>
                <div className="stars py-2">
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                </div>
              </div>
              <div className="review-para pt-2">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                  velit officia ratione ullam unde odio, sed optio itaque iste
                  magnam blanditiis dicta illo eum.
                </p>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="review-box">
              <div className="client-img">
                <div className="user-img">
                  <div className="user-icon">
                    <img
                      src="assets/images/icons/users.jpg"
                      alt=""
                      style={{ width: 60 }}
                    />
                  </div>
                  <div className="user-name">
                    <h5>Ethan Mitchell</h5>
                    <span>Students</span>
                  </div>
                </div>
                <div className="stars py-2">
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                </div>
              </div>
              <div className="review-para pt-2">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos
                  velit officia ratione ullam unde odio, sed optio itaque iste
                  magnam blanditiis dicta illo eum.
                </p>
              </div>
            </div>
          </div> */}
              </OwlCarousel>
            )}
          </div>
        </div>
      </section>

      {/* Modal */}

      {/* floating-icons  */}
      <div className="quick_contact">
        <a
          href="https://api.whatsapp.com/send?phone=#&text=hello"
          className="qwhtsapp"
          target="_blank"
        >
          <i className="fa fa-whatsapp quick_contact_icon" />
        </a>
        <a href="#" className="qcall" target="_blank">
          <i className="fa fa-phone quick_contact_icon" />
        </a>
      </div>
      <div className="qiuck-enquiry">
        <button
          type="button"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          Enquiry Now
        </button>
      </div>
    </>
  );
}
