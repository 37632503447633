/** @format */

import React, { useEffect, useState } from "react";
import ApiService from "../../services/apiService";
import { toast } from "react-toastify";
import { Image_PATH } from "../../config/constant";
import Select from "react-select";
import { Link, useLocation, useParams } from "react-router-dom";
import Pagination from "../../elements/Pagination";
import OwlCarousel from "react-owl-carousel";
export default function PackageList() {
  const [Destination, setDestination] = useState([]);
  const [selectDestination, setSelectDestination] = useState([]);
  const [selectTheme, setSelectTheme] = useState([]);
  const [Themes, setThemes] = useState([]);
  const [cityValue, setCityValue] = useState("");
  const [dropdown, setDropdown] = useState({ 1: true, 2: true });
  const [banners, setBanners] = useState([]);
  const [Package, setPackage] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterPackage, setFilterPackage] = useState({});
  const [range, setRange] = useState({ left: 0, right: 150000 });
  const [popularTours, setPopularTours] = useState([]);
  const { url, type, destination_url, theme_url } = useParams();
  const [selectedDestination, setSelectedDestination] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState([]);
  const [page, setPage] = useState({});
  const [metaTags, setMetaTags] = useState("");
  const [amens, setAmens] = useState([]);
  const [popularPackage, setPopularPackage] = useState([]);
  const [filterForm, setFilterForm] = useState({
      destination_id: 0,
      theme_id: 0,
    });
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(prevState => !prevState);
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  const location = useLocation();
  useEffect(() => {
    getAmenties();
    getDestinationList();
    getThemesList();
    getBannerList();
    getPopularPackageList();
  }, []);

  const getBannerList = async () => {
    ApiService.post("banner/get-data", {
      page: 1,
      limit: 6,
      status: "1",
      type: "2",
    })
      .then((data) => {
        if (data?.Banners) {
          setBanners(data.Banners);
        } else {
          setBanners([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getPackageList = async (filterData = {}) => {
    setPackage([])
    ApiService.post("package/get-data", {
      page: currentPage + 1,
      limit: 1000,
      type: type,
      ...filterPackage,
      ...filterData,
    })
      .then((data) => {
        if (data?.Packages && data?.Packages?.length) {
          setPackage(data.Packages);
          setPageCount(data?.Package?.pagination?.totalCount);
        } else {
          setPackage([]);
          setPageCount(0);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getPopularPackageList = async () => {
    if (type) {
      ApiService.post("package/get-data", {
        page: 1,
        limit: 15,
        type: type,
        popular: "1",
      })
        .then((data) => {
          if (data?.Packages) {
            setPopularPackage(data.Packages);
          } else {
            setPopularPackage([]);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data);
        });
    }
  };

  const handlePageClick = (selectedPage) => {
    const clickedPage = selectedPage.selected;
    if (clickedPage !== currentPage) {
      setCurrentPage(clickedPage);
    }
  };

  const changeLeft = (e, type) => {
    let rng={};
    if (type === "left") {
      if (+e?.target?.value > +range?.right) {
        rng={
          left: +range?.right,
          right: +range?.right,
        };
        setRange({
          left: +range?.right,
          right: +range?.right,
        });
      } else {
        rng={
          left: +e?.target?.value,
          right: +range?.right,
        }
        setRange({
          left: +e?.target?.value,
          right: +range?.right,
        });
      }
    } else {
      if (+e?.target?.value < +range?.left) {
        rng={
          left: +range?.left,
          right: +range?.left,
        }
        setRange({
          left: +range?.left,
          right: +range?.left,
        });
      } else {
        rng={
          left: +range?.left,
          right: +e?.target?.value,
        }
        setRange({
          left: +range?.left,
          right: +e?.target?.value,
        });
      }
    }
    getPackageList({ ...filterPackage, ...rng });
  };
  // useEffect(() => {
  //   alert()
  //   getPackageList({ ...filterPackage, ...range });
  // }, [range.left,range.right]);

  const getAmenties = async () => {
    ApiService.post("amen/get-data", { page: 1, limit: 10000, status: "1" })
      .then((data) => {
        if (data?.Amens) {
          setAmens(data.Amens);
        } else {
          setAmens([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  //   // Function to toggle dropdown menu
  // const toggleDropdown = (filterId) => {
  //     // alert()
  //     // const dropdownContainer = document.querySelector(`.dropdown-container[data-filter-id="${filterId}"]`);
  //     // const dropdownMenu = dropdownContainer.querySelector('.dropdown-menu');
  //     // const dropdownArrow = dropdownContainer.querySelector('.arrow');
  //     // dropdownMenu.classList.toggle('open');
  //     // dropdownArrow.classList.toggle('open');
  //     alert(filterId)
  //         dropdown[filterId] = dropdown?.[filterId] ? false : true;
  //         //alert(dropdown[filterId])
  //     setDropdown(dropdown)
  // };

  // // Event listener for dropdown buttons
  // document.querySelectorAll('.dropdown-button').forEach(button => {
  //     button.addEventListener('click', () => {
  //         const filterId = button.closest('.dropdown-container').dataset.filterId;
  //         toggleDropdown(filterId);
  //     });
  // });

  // useEffect(()=>{
  //   getPackageList();
  // },[filterPackage])

  useEffect(() => {
    if (Destination.length > 0 && Themes?.length) {
      let data1 = [];
      let data = url
        ? Destination?.filter((d) => d?.url === url)
        : Destination?.filter((d) => d?.url === destination_url);
      if (!url && theme_url) {
        data1 = Themes?.filter((d) => d?.url === theme_url);
        filterPackage["theme_id"] = [data1?.[0]?.id];
      }
      let paths = location?.pathname?.split("/");
      if (paths.includes("package-list")) {
        data?.[0]?.id && setSelectedDestination([data?.[0]?.id]);
        data1?.[0]?.id && setSelectTheme([data1?.[0]?.id]);
        setPage(data?.[0]);
        setMetaTags(data?.[0]?.header);
        setFilterPackage((prevState) => {
          return { ...prevState, destination_id: [data?.[0]?.id] };
        });
        getPackageList({ ...filterPackage, destination_id: [data?.[0]?.id] });
      }
      let dest = [];
      Destination?.map((d) => {
        dest.push({ value: d?.id, label: d?.name });
      });
      setSelectDestination(dest);
    }
  }, [Destination,Themes]);

  useEffect(() => {
    if (Themes.length > 0) {
      let data = url
        ? Themes?.filter((d) => d?.url === url)
        : Themes?.filter((d) => d?.url === theme_url);
        console.log(data,"ddddddddddddddddddddddd",Themes)
      let paths = location?.pathname?.split("/");
      if (paths.includes("package-list-by-themes")) {
        data?.[0]?.id && setSelectedTheme([data?.[0]?.id]);
        setPage(data?.[0]);
        setMetaTags(data?.[0]?.header);

        setFilterPackage((prevState) => {
          return { ...prevState, theme_id: [data?.[0]?.id] };
        });
        getPackageList({ ...filterPackage, theme_id: [data?.[0]?.id] });
      }
    }
  }, [Themes]);

  const getDestinationList = async () => {
    ApiService.post("destination/get-data-type", {
      page: 1,
      limit: 10000,
      type: type,
    })
      .then((data) => {
        if (data?.Destinations) {
          setDestination(data.Destinations);
        } else {
          setDestination([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const getThemesList = async () => {
    ApiService.post("themes/get-data-type", {
      page: 1,
      limit: 1000,
      type: type,
    })
      .then((data) => {
        if (data?.Themess) {
          setThemes(data.Themess);
          let theme = [];
          data.Themess?.map((d) => {
            theme.push({ value: d?.id, label: d?.name });
          });
          setSelectTheme(theme);
        } else {
          setThemes([]);
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const changeDestination = (event) => {
    const value = +event.target.value;
    const checked = event.target.checked;

    setSelectedDestination((prevSelectedItems) => {
      // If the checkbox is checked, add the value to the array
      if (checked) {
        getPackageList({
          ...filterPackage,
          destination_id: [...prevSelectedItems, +value],
        });
        return [...prevSelectedItems, +value];
      }
      // If unchecked, remove the value from the array
      else {
        getPackageList({
          ...filterPackage,
          destination_id: prevSelectedItems.filter((item) => item !== +value),
        });
        return prevSelectedItems.filter((item) => item !== +value);
      }
    });
    setFilterPackage((prevState) => {
      return { ...prevState, destination_id: selectedDestination };
    });
  };

  const changeThemes = (event) => {
    const value = +event.target.value;
    const checked = event.target.checked;

    setSelectedTheme((prevSelectedItems) => {
      // If the checkbox is checked, add the value to the array
      if (checked) {
        getPackageList({
          ...filterPackage,
          theme_id: [...prevSelectedItems, +value],
        });
        return [...prevSelectedItems, value];
      }
      // If unchecked, remove the value from the array
      else {
        getPackageList({
          ...filterPackage,
          theme_id: prevSelectedItems.filter((item) => item !== value),
        });
        return prevSelectedItems.filter((item) => item !== value);
      }
    });

    setFilterPackage((prevState) => {
      return { ...prevState, theme_id: selectedTheme };
    });
  };

  useEffect(() => {
    // If metaTags is not empty, inject it into the head
    if (metaTags) {
      const head = document.head;
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = metaTags;
      // Inject all meta tags into the head
      Array.from(tempDiv.children).forEach((child) => {
        head.prepend(child);
      });

      const body = document.body;
      const tempDivbody = document.createElement("div");
      tempDivbody.innerHTML = page?.footer;
      // Inject all meta tags into the head
      Array.from(tempDivbody.children).forEach((child) => {
        body.append(child);
      });
    }
  }, [metaTags]);

  const options1 = {
    items: 1, // Only show one item at a time
    loop: true, // Allow looping
    margin: 10, // Add some margin between items
    responsive: {
      0: {
        items: 1, // 1 item on smaller screens
      },
      600: {
        items: 1, // 1 item on medium screens
      },
      1000: {
        items: 1, // 1 item on larger screens
      },
    },
    nav: true, // Enable navigation buttons
    dots: true, // Enable dots navigation
  };


  const setChangeEvent = async (e, name) => {
    setFilterForm((prev) => {
      return {
        ...prev,
        [name]: e?.value,
      };
    });
  };

  const getPackage = () => {
    if(Destination && Destination?.length){
    let destination1 = Destination?.filter(
      (dt) => dt?.id === filterForm?.destination_id
    );
    let theme_id = Themes?.filter((dt) => dt?.id === filterForm?.theme_id);
    if(theme_id?.length){
      window.location.href = `/package-list/filter/${destination1?.[0]?.url}/${theme_id?.[0]?.url}`;
    }else
      window.location.href = `/package-list/filter/${destination1?.[0]?.url}`;
  }
  };

  return (
    <>
      {/* banner section  */}
      <section className="banner-section" key={Package}>
        <div className="banner">
          <img src={theme_url ? `${Image_PATH}Themes/${Package?.[0]?.theme?.banner}` : `${Image_PATH}destination/${Package?.[0]?.destination?.banner}`} alt="" width="100%" />
        </div>
        {/* search form start  */}
        <div className="search-form">
          <div className="search-form-box">
            <div className="form">
              <div className="form-inputs">
                <div className="search-form-input-1" id="first-select">
                  <i className="fa fa-map-marker" />
                  {/* <select id="Cityselect">
                <option value="disable">Select City</option>
                <option value="AL">Uttarakhand</option>
                <option value="WY">Himachal</option>
                <option value="WY">Kashmir</option>
                <option value="WY">Kerala</option>
                <option value="WY">Goa</option>
                <option value="WY">Rajasthan</option>
              </select> */}
                  <Select
                    id="Cityselect"
                    options={selectDestination}
                    onChange={(e) => setChangeEvent(e, "destination_id")}
                    placeholder="Select..."
                  />
                </div>
                <div className="search-form-input-2" id="second-select">
                  <i className="fa fa-flag-o" />
                  {/* <select id="Tourselect">
                <option value="disable">Select Your Tour</option>
                <option value="AL">Honeymoon Tour Package</option>
                <option value="WY">Car Tour Package</option>
                <option value="WY">Couple Tour package</option>
                <option value="WY">Family Tour Package</option>
                <option value="WY">Group Tour Package</option>
              </select> */}

                  <Select
                    id="Tourselect"
                    options={selectTheme}
                    onChange={(e) => setChangeEvent(e, "theme_id")}
                    placeholder="Select..."
                  />
                </div>
                <div className="search-form-button">
                  <button type="button" onClick={() => getPackage()} className="btn btn-search text-light">
                    Search <i className="fa fa-send-o" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* search form end  */}
      </section>
      {/* banner section end  */}
      <section className="page-titles">
        <div className="container-fluid ">
          <div className="page-head px-lg-3">
            <h1>{theme_url ? Package?.[0]?.theme?.banner_title : Package?.[0]?.destination?.banner_title }</h1>
            <p>
            {theme_url ? Package?.[0]?.theme?.banner_content : Package?.[0]?.destination?.banner_content }
            </p>
          </div>
        </div>
      </section>
      <section className="details-package">
        <div className="container-fluid ">
          <div className="details-package-inner">
            <div className="row">
              <div className="col-lg-3">
                <div className="filter-button fixed-bottom d-lg-none">
                  <button id="filterBtn" className="btn btn-primary" onClick={toggleDropdown}>
                    Apply Filter <i className="fa fa-filter" />
                  </button>
                </div>
                <div
                  className={isDropdownVisible ? "filter-dropup filter-boxes-mobile show" :  "filter-dropup filter-boxes-mobile"}
                  id="filterDropUp"
                >
                  <div className="filter-content">
                    <button id="closeBtn" className="close-icon" onClick={closeDropdown}>
                      ×
                    </button>
                    <div className="filter-container">
                      <div className="dropdown-container" data-filter-id={3}>
                        <div className="dropdown-button">
                          <h5>Budget (per person)</h5>
                          <span className="arrow">
                            <i className="fa fa-angle-up" />
                          </span>
                        </div>
                        <div className="dropdown-menu open">
                          <div id="range-one" className="range-container">
                            <div className="sliders_control">
                              <input
                                type="range"
                                className="dual-range left-range"
                                min={0}
                                max={150000}
                                defaultValue={0}
                                value={range?.left}
                                step={1000}
                                onChange={(e) => changeLeft(e, "left")}
                              />
                              <input
                                type="range"
                                className="dual-range right-range"
                                min={0}
                                max={150000}
                                defaultValue={150000}
                                step={1000}
                                value={range?.right}
                                onChange={(e) => changeLeft(e, "right")}
                              />
                            </div>
                            <div className="numbers">
                              <div className="start">
                                <i className="fa fa-inr" />
                                <span>{range?.left}</span>
                              </div>
                              <div className="end">
                                <i className="fa fa-inr" />
                                <span>{range?.right}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="filter-container">
                      <div className="dropdown-container" data-filter-id={1}>
                        <div className="dropdown-button">
                          <h5> Cities</h5>
                          <span className="arrow">
                            <i
                              className={
                                dropdown?.[1]
                                  ? "fa fa-angle-up"
                                  : "fa fa-angle-down"
                              }
                            />
                          </span>
                        </div>
                        <div
                          className={
                            dropdown?.[1]
                              ? "dropdown-menu open"
                              : "dropdown-menu"
                          }
                        >
                          <input
                            type="text"
                            className="search-box"
                            placeholder="Search city..."
                            onChange={(e) => setCityValue(e?.target?.value)}
                          />
                          <div className="checkbox-group">
                            {Destination &&
                              Destination?.length > 0 &&
                              Destination?.filter((f) =>
                                f?.name?.includes(cityValue)
                              )?.map((d, k) => {
                                return (
                                  <div
                                    className="check-box-group-input"
                                    key={k}
                                  >
                                    <label>
                                      <input
                                        checked={selectedDestination?.includes(
                                          +d?.id
                                        )}
                                        type="checkbox"
                                        onChange={(e) => changeDestination(e)}
                                        value={d?.id}
                                      />{" "}
                                      {d?.name}
                                    </label>
                                    <span className="input-number">
                                      ({d?.packages?.[0]?.count})
                                    </span>
                                  </div>
                                );
                              })}

                            {/* <span className="hidden-content">
                          <div className="check-box-group-input">
                            <label>
                              <input type="checkbox" defaultValue="Bangalore" />{" "}
                              Bangalore
                            </label>
                            <span className="input-number">(1)</span>
                          </div>
                        </span>
                        <button className="read-more-btn">Read More </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="filter-container">
                      <div className="dropdown-container" data-filter-id={2}>
                        <div className="dropdown-button">
                          <h5>Themes</h5>
                          <span className="arrow">
                            <i
                              className={
                                dropdown?.[2]
                                  ? "fa fa-angle-up"
                                  : "fa fa-angle-down"
                              }
                            />
                          </span>
                        </div>
                        <div
                          className={
                            dropdown?.[2]
                              ? "dropdown-menu open"
                              : "dropdown-menu"
                          }
                        >
                          <div className="checkbox-group">
                            {Themes &&
                              Themes?.length > 0 &&
                              Themes?.map((d, k) => {
                                return (
                                  <div
                                    className="check-box-group-input"
                                    key={k}
                                  >
                                    <label>
                                      <input
                                        onChange={(e) => changeThemes(e)}
                                        type="checkbox"
                                        defaultValue=""
                                        value={d?.id}
                                        checked={selectedTheme?.includes(d?.id)}
                                      />{" "}
                                      {d?.name}
                                    </label>
                                    <span className="input-number">
                                      ({d?.packages?.[0]?.count})
                                    </span>
                                  </div>
                                );
                              })}
                            {/*                         
                        <span className="hidden-content">
                          <div className="check-box-group-input">
                            <label>
                              <input type="checkbox" defaultValue="Bangalore" />{" "}
                              Wildlife
                            </label>
                            <span className="input-number">(1)</span>
                          </div>
                        </span>
                        <button className="read-more-btn">Read More </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="offer-img">
                      <img
                        src="assets/images/inner-page-offer.png"
                        alt=""
                        width="100%"
                      />
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                {Package &&
                  Package?.length > 0 &&
                  Package?.map((p, k) => {
                    return (
                      <>
                        <div className="inner-page-box" key={k}>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="inner-packages-image">
                                {p?.promotion === "1" && (
                                  <div className="promotion-tag">
                                    <span>Promotion</span>
                                  </div>
                                )}
                                <img
                                  src={`${Image_PATH}Package/${p?.image_url}`}
                                  alt={p?.alt_image}
                                  width="100%"
                                />
                                <div className="inner-package-img-overlay">
                                  <h6>{p?.theme?.name}</h6>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-5">
                              <div className="package-incluision-box">
                                
                                <div className="inner-package-name">
                                  <h4>{p?.title}</h4>
                                </div>
                                <div className="inner-package-date">
                                  <span>{p?.duration}</span>
                                </div>
                                <div className="inner-package-dest">
                                  <p>
                                    <b> Destination: </b> 
                                    {p?.destination_cover}
                                  </p>
                                </div>
                                <div className="inner-package-rating">
                                  <span>
                                    <div className="inner-star">
                                      <img
                                        src="assets/images/icons/star.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div className="inner-star">
                                      <img
                                        src="assets/images/icons/star.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div className="inner-star">
                                      <img
                                        src="assets/images/icons/star.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div className="inner-star">
                                      <img
                                        src="assets/images/icons/star.svg"
                                        alt=""
                                      />
                                    </div>
                                    <div className="inner-star half-star">
                                      <img
                                        src="assets/images/icons/star.svg"
                                        alt=""
                                      />
                                    </div>
                                    ({p?.rating_count} reviews)
                                  </span>
                                </div>
                                <div className="package-itenary">
                                  {JSON.parse(p?.amenties_id)?.map((pi, k1) => {
                                    return (
                                      <div className="itneary-box" key={k1}>
                                        <div className="itneray-img">
                                          <img
                                            alt={
                                              amens?.filter(
                                                (am) => +am?.id === +pi
                                              )?.[0]?.title
                                            }
                                            src={`${Image_PATH}Amen/${
                                              amens?.filter(
                                                (am) => +am?.id === +pi
                                              )?.[0]?.image_url
                                            }`}
                                          />
                                          <div className="itneray-name">
                                            <span>
                                              {
                                                amens?.filter(
                                                  (am) => +am?.id === +pi
                                                )?.[0]?.title
                                              }
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                  {/* <div className="itneary-box">
                                    <div className="itneray-img">
                                      <img
                                        src="assets/images/icons/lunch.png"
                                        alt=""
                                      />
                                      <div className="itneray-name">
                                        <span>meals</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="itneary-box">
                                    <div className="itneray-img">
                                      <img
                                        src="assets/images/icons/bed.png"
                                        alt=""
                                      />
                                      <div className="itneray-name">
                                        <span>Hotel</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="itneary-box">
                                    <div className="itneray-img">
                                      <img
                                        src="assets/images/icons/tranfer.png"
                                        alt=""
                                      />
                                      <div className="itneray-name">
                                        <span>Transfer</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="itneary-box">
                                    <div className="itneray-img">
                                      <img
                                        src="assets/images/icons/sightdeeing.png"
                                        alt=""
                                      />
                                      <div className="itneray-name">
                                        <span>Sightseeing</span>
                                      </div>
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="package-pricing-box">
                                <div className="del-price">
                                  <del>
                                    {" "}
                                    <h4>
                                      <i className="fa fa-inr" />{" "}
                                      {p?.price || 0}
                                    </h4>{" "}
                                  </del>
                                </div>
                                <div className="main-price">
                                  <h4>
                                    <i className="fa fa-inr" />{" "}
                                    {p?.offer_price || 0}
                                  </h4>
                                </div>
                                <div className="price-para">
                                  <span> Per Person On Twin Sharing</span>
                                </div>
                                <div className="buttons-group">
                                  <div className="deatails-btn">
                                    <Link
                                      to={`/package-details/${p?.url}`}
                                      className="btn btn-details"
                                    >
                                      {" "}
                                      View Details
                                    </Link>
                                    <button data-bs-toggle="modal" data-bs-target="#exampleModal" className="btn btn-enquiry">
                                      {" "}
                                      Enquiry
                                    </button>
                                  </div>
                                  <div className="contact-btn">
                                    <Link
                                      to={`tel:${p?.phone}`}
                                      className="btn btn-call"
                                    >
                                      {" "}
                                      Call
                                    </Link>
                                    <Link
                                      to={`https://api.whatsapp.com/send?phone=${p?.whatsapp}&text=hello`}
                                      className="btn btn-whatsapp"
                                    >
                                      {" "}
                                      Whatsapp
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </>
                    );
                  })}
                
                <div className="pagignation-box">
                  <nav aria-label="Page navigation example">
                    

                    {/* <Pagination
                      pageCount={pageCount}
                      handlePageClick={handlePageClick}
                    /> */}
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* special travels area start  */}
      <section className="special-travels pt-5 pb-5">
        <div className="container-fluid">
          <div className="title">
            <h2>Our Travel Specialization</h2>
            <p>
              Whatever you're looking for places for a vacation. We are here to
            </p>
          </div>
          <div className="row mt-5">
            <div className="col-lg-8 col-md-12 col-12">
              <div className="row">
                {Themes &&
                  Themes?.length > 0 &&
                  Themes?.map((d, k) => {
                    return (
                      <div className="col-lg-4 col-md-6 col-12 mb-3">
                        <div className="special-packages">
                          <a href={`package-list-by-themes/${d?.url}`}>
                            <div className="special-images">
                              <img
                                src={`${Image_PATH}Themes/${d?.image_url}`}
                                alt=""
                                width="100%"
                              />
                            </div>
                            <div className="special-text">
                              <div className="special-text-name">
                                <h6>{d?.name}</h6>
                                <p>{d?.packages?.[0]?.count} Tour Packages</p>
                              </div>
                              <div className="special-text-review">
                                <span>
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                  <i className="fa fa-star" />
                                </span>
                                <p>62 Review</p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="special-banner">
                <div className="slider">
                  {banners &&
                                        banners?.length > 0 &&
                                        banners
                                          ?.filter((b) => b.type === "2")?.length>0 && (
                                    <OwlCarousel
                                    {...options1}
                                      className="owl-carousel owl-theme"
                                      
                                    >
                                      {banners &&
                                        banners?.length > 0 &&
                                        banners
                                          ?.filter((b) => b.type === "2")
                                          ?.map((tm,k) => {
                                            return (
                                              <div className="item" key={k}>
                                                { tm?.url ?  <Link to={`${tm?.url}`}>
                                                <img
                                                  src={`${Image_PATH}Banner/${tm?.image_url}`}
                                                  alt={tm?.alt_image}
                                                  width="100%"
                                                />
                                                </Link> : <img
                                                  src={`${Image_PATH}Banner/${tm?.image_url}`}
                                                  alt={tm?.alt_image}
                                                  width="100%"
                                                /> }
                                              </div>
                                            );
                                          })}
                                      {/* <div className="item">
                                    <img src="assets/images/b-2.jpg" alt="" width="100%" />
                                  </div>
                                  <div className="item">
                                    <img src="assets/images/b-3.jpg" alt="" width="100%" />
                                  </div> */}
                                    </OwlCarousel>
                                          )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* special travels area end  */}
      {/* popular tour area start  */}
      <section className="popular-tour pt-5 pb-5">
        <div className="container-fluid">
          <div className="title">
            <h2>Our Most Popular Tours</h2>
            <p>
              Whatever you're looking for places for a vacation. We are here to
            </p>
          </div>
          <div className="row pt-5">
            {popularPackage &&
                          popularPackage?.length > 0 &&
                          popularPackage?.map((p, k) => {
                            return (
                              <div className="col-lg-4 col-12 mb-3" key={k}>
                                <Link to={`/package-details/${p?.url}`}>
                                  <div className="popular-packages py-2 ">
                                    <div className="row">
                                      <div className="col-lg-12 col-md-6 col-12">
                                        <div className="row popular-packages-box m-1">
                                          <div className="col-4">
                                            <div className="popular-package-img">
                                              <img
                                                src={`${Image_PATH}Package/${p?.image_url}`}
                                                alt={p?.alt_image}
                                                width="100%"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-8">
                                            <div className="popular-package-text">
                                              <h5>{p?.title}</h5>
                                              <span>{p?.duration}</span>
                                              <p>{p?.destination_cover}</p>
                                              <h4>
                                                <i className="fa fa-inr" />{" "}
                                                {p?.offer_price || 0}/-{" "}
                                                <span>Per Person</span>
                                              </h4>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            );
                          })}
          </div>
        </div>
      </section>
      {/* popular tour area end  */}
    </>
  );
}
