/** @format */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ApiService from "../../services/apiService";
import { toast } from "react-toastify";
import { Image_PATH } from "../../config/constant";
import PreviewBooking from "./PreviewBooking";
export default function Checkout() {
  const { url, tourtype_id } = useParams();
  const [packages, setPackages] = useState({});
  const [guestCount, setGuestCount] = useState([
    { name: "", gender: "", age: "" },
  ]);
  const [settingData, setSettingsData] = useState({});
  const [checkOut, setCheckout] = useState(false);
  const [bookingDetails, setBookingDetails] = useState({
    from_date: "",
    // to_date: "",
    adult: "",
    children: "",
    name: "",
    phone: "",
    email: "",
    country: ""
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [page, setPage] = useState({});
  const [metaTags, setMetaTags] = useState("");

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentMonth = new Date().getMonth(); // Get current month index (0-11)
  let result = [];

  for (let i = 0; i < 12; i++) {
    let monthIndex = (currentMonth + i) % 12; // Wrap around using modulo
    result.push({ month: months[monthIndex], index: monthIndex + 1 }); // Add month and its index (1-based)
  }
  console.log(result, "rrrrrrrrrrrrrrrrrrrrrrrrr");
  useEffect(() => {
    getSettings();
    
  }, [url]);

  const getSettings = async () => {
    ApiService.get("settings/1")
      .then(async (data) => {
        if (data?.data) {
          setSettingsData(data.data);
         
        } else {
          setSettingsData({});
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  useEffect(()=>{
    if (url) getPackageById();
  },[settingData])

  const getPackageById = async () => {
    ApiService.get(`package/package-details/${url}`)
      .then((data) => {
        if (data?.data) {
          let packageDataPrice = data?.data?.package_prices?.filter(
            (pp) => data?.data?.offer_price === pp?.offer_price
          );
          data["data"]["tourtype_id"] = packageDataPrice?.[0]?.Tourtype?.id;
          data["data"]["price"] = packageDataPrice?.[0]?.price;
          data["data"]["offer_price"] = packageDataPrice?.[0]?.offer_price;

          data["data"]["base_price"]=data["data"]?.offer_price * (bookingDetails?.children ? bookingDetails?.children : 0)+ data["data"]?.offer_price * (bookingDetails?.adult ? bookingDetails?.adult : 1) ;
          data["data"]["tax"]=data["data"]["base_price"] * settingData?.gst/100 ;

          setPackages(data?.data);
          setPage(data?.data);
          setMetaTags(data?.data?.header);
          setBookingDetails((prev) => {
            return {
              ...prev,
              from_date: data?.data?.from_date
             // to_date: data?.data?.to_date,
            };
          });
        }
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  const addMoreGuest = async () => {
    let guestCountTemp = guestCount;
    guestCountTemp.push({ name: "", gender: "", age: "" });
    setGuestCount([...guestCount]);
  };

  const removeGuest = async (index) => {
    let guestCountTemp = guestCount;
    guestCountTemp.splice(index, 1);
    setGuestCount([...guestCount]);
  };

  const changeFiled = (e, name, index) => {
    let guestCountTemp = guestCount;
    let xx = guestCountTemp.find((g, k) => k === index);
    xx[name] = e?.target?.value;
    setGuestCount([...guestCount]);
  };

  const changeBookingFiled = (e, name) => {
    if (name === "month") {
      const currentDate = new Date(),
        month = e?.target?.value;
      let filterDates = packages?.package_dates.filter((dateStr) => {
        const date = new Date(dateStr?.from_date); // Convert string to Date object
        return date > currentDate && +date.getMonth()+1 === +month;
      });
      setPackages((prev) => {
        return {
          ...prev,
          filterDates: filterDates,
        };
      });
    }
  
    setBookingDetails((prev) => {
      return {
        ...prev,
        [name]: e?.target?.value,
      };
    });
  };

  useEffect(() => {
    if(bookingDetails?.from_date){
      const checkDateObj = new Date(bookingDetails?.from_date);
      const resultData = packages?.package_special_prices?.find(item => {
        const fromDate = new Date(item.from_date);
        const toDate = new Date(item.to_date);
        return checkDateObj >= fromDate && checkDateObj <= toDate;
      });
      if( resultData && Object.keys(resultData).length){
        let base_price=resultData?.offer_price * (bookingDetails?.children ? bookingDetails?.children : 0)+ resultData?.offer_price * (bookingDetails?.adult ? bookingDetails?.adult : 1) ;
        let tax=+base_price * settingData?.gst/100 ;
        setPackages((prev) => {
          return {
            ...prev,
            price: resultData?.price,
            offer_price: resultData?.offer_price,
            base_price: base_price,
            tax: tax
          };
        });

      }
    }
  }, [bookingDetails?.adult, bookingDetails?.children, bookingDetails?.from_date, packages?.package_special_prices])
  
  const changeTerms = (e) => {
    setCheckout(e?.target?.checked);
  };

  const preview = () => {
    console.log(guestCount,bookingDetails,"qqqqqqqqqqqqqqqqqqqqqqq")
    const isAnyFieldEmpty = guestCount.some((obj) =>
      Object.values(obj).some(
        (value) => value === "" || value === null || value === undefined
      )
    );
    let bookingInfo = Object.values(bookingDetails).some(
      (value) => value === "" || value === null || value === undefined
    );
    if (isAnyFieldEmpty || bookingInfo) {
      toast.warning("Please fill all fields");
      setIsModalVisible(false);
      return;
    }
    setIsModalVisible(true);
  };

  const confirmBooking = async () => {
    setIsModalVisible(false);
    ApiService.post("package-booking", {
      ...bookingDetails,
      package_id: packages?.id,
      guests: guestCount,
      base_price: packages?.base_price,
      tax: packages?.tax
    })
      .then((data) => {
        toast.success(data?.message);
        setTimeout(() => {
          window.location.href = `/fix-package-details/${packages?.url}`;
        }, 2000);
      })
      .catch((err) => {
        toast.error(err?.response?.data);
      });
  };

  useEffect(() => {
    // If metaTags is not empty, inject it into the head
    if (metaTags) {
      const head = document.head;
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = metaTags;
      // Inject all meta tags into the head
      Array.from(tempDiv.children).forEach((child) => {
        head.prepend(child);
      });

      const body = document.body;
      const tempDivbody = document.createElement("div");
      tempDivbody.innerHTML = page?.footer;
      // Inject all meta tags into the head
      Array.from(tempDivbody.children).forEach((child) => {
        body.append(child);
      });
    }
  }, [metaTags]);

  return (
    <>
      <section className="page-titles">
        <div className="container-fluid px-lg-5">
          <div className="check-out-page-heading">
            <h1>Complete Your Booking</h1>
          </div>
        </div>
      </section>
      <section className="details-package-checkout">
        <div className="container-fluid px-lg-5">
          <div className="details-package-checkout-inner">
            <div className="row">
              <div className="col-lg-9">
                <div className="inner-page-box-checkout mb-3">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="inner-packages-image">
                        <img
                          src={`${Image_PATH}Package/${packages?.image_url}`}
                          alt=""
                          width="100%"
                        />
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="package-incluision-box-fixed">
                        <div className="fixed-inner-package-date">
                          <span>{packages?.duration}</span>
                        </div>
                        <div className="inner-package-name-fixed">
                          <h4>{packages?.title}</h4>
                        </div>
                        <div className="inner-package-dest-fixed">
                          <p>
                            <b> Destination: </b> Arrive
                            {packages?.destination_cover}e{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inner-page-box-checkout mb-3">
                  <div className="booking-details">
                    <h5>
                      <span> Enter Booking Details</span>
                    </h5>
                  </div>
                  <div className="checkout-form">
                    <form className="mt-3" action="">
                      <div className="row ">
                        {packages?.booking_type === "1" && (
                          <>
                            <div className="col-md-3">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                Month
                              </label>
                              <select
                                onChange={(e) => changeBookingFiled(e, "month")}
                                className="form-select"
                                aria-label="Default select example"
                              >
                                <option selected="">Select Month</option>
                                {result &&
                                  result?.length > 0 &&
                                  result?.map((r, k) => {
                                    return (
                                      <option
                                        selected={
                                          bookingDetails?.month === r?.index
                                        }
                                        value={r?.index}
                                      >
                                        {r?.month}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>

                            <div className="col-md-3">
                              <label
                                htmlFor="inputPassword5"
                                className="form-label"
                              >
                                From Date
                              </label>
                              {/* <input
                                onChange={(e) =>
                                  changeBookingFiled(e, "from_date")
                                }
                                type="date"
                                id="inputPassword5"
                                className="form-control"
                                value={bookingDetails?.from_date}
                                readOnly={true}
                              /> */}

                              <select
                                onChange={(e) => changeBookingFiled(e, "from_date")}
                                className="form-select"
                                aria-label="Default select example"
                              >
                                <option selected="">Select Date</option>
                                {packages?.filterDates && packages?.filterDates?.length>0  &&
                                  packages?.filterDates?.map((r, k) => {
                                    return (
                                      <option
                                        selected={
                                          bookingDetails?.from_date === r?.from_date
                                        }
                                        value={r?.from_date}
                                      >
                                        {r?.from_date}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </>
                        )}
                        {packages?.booking_type === "0" && (
                          <div className="col-md-3">
                            <label
                              htmlFor="inputPassword5"
                              className="form-label"
                            >
                              From Date
                            </label>
                            <input
                              onChange={(e) =>
                                changeBookingFiled(e, "from_date")
                              }
                              type="date"
                              id="inputPassword5"
                              className="form-control"
                              value={bookingDetails?.from_date}
                              readOnly={true}
                            />
                          </div>
                        )}
                        {/* <div className="col-md-3">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            To Date
                          </label>
                          <input
                            onChange={(e) => changeBookingFiled(e, "to_date")}
                            type="date"
                            id="inputPassword5"
                            className="form-control"
                            value={bookingDetails?.to_date}
                            readOnly={true}
                          />
                        </div> */}
                        <div className="col-md-3">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            Adults (Above 12 years) *
                          </label>
                          <input
                            onChange={(e) => changeBookingFiled(e, "adult")}
                            type="number"
                            id="inputPassword5"
                            className="form-control"
                            value={bookingDetails?.adult}
                          />
                        </div>
                        <div className="col-md-3">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            Children (5-12 years)
                          </label>
                          <input
                            onChange={(e) => changeBookingFiled(e, "children")}
                            type="number"
                            id="inputPassword5"
                            className="form-control"
                            value={bookingDetails?.children}
                          />
                        </div>
                        <div className="col-md-3 mt-3">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            Name
                          </label>
                          <input
                            onChange={(e) => changeBookingFiled(e, "name")}
                            type="text"
                            id="inputPassword5"
                            className="form-control"
                            placeholder="Enter Full Name"
                            value={bookingDetails?.name}
                          />
                        </div>
                        <div className="col-md-3 mt-3">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            Phone No.
                          </label>
                          <input
                            onChange={(e) => changeBookingFiled(e, "phone")}
                            type="tel"
                            id="inputPassword5"
                            className="form-control"
                            placeholder={11223462}
                            value={bookingDetails?.phone}
                          />
                        </div>
                        <div className="col-md-3 mt-3">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            Email Id.
                          </label>
                          <input
                            onChange={(e) => changeBookingFiled(e, "email")}
                            type="email"
                            id="inputPassword5"
                            className="form-control"
                            placeholder="example@gmail.com"
                            value={bookingDetails?.email}
                          />
                        </div>
                        <div className="col-md-3 mt-3">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            Country
                          </label>
                          <input
                            onChange={(e) => changeBookingFiled(e, "country")}
                            type="text"
                            id="inputPassword5"
                            className="form-control"
                            placeholder="India"
                            value={bookingDetails?.country}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="inner-page-box-checkout mb-3">
                  <div className="booking-details">
                    <h5>
                      <span> Traveller Details</span>
                    </h5>
                  </div>
                  <div className="checkout-form">
                    <form action="" id="dynamic-form">
                      {guestCount?.length > 0 &&
                        guestCount.map((g, k) => {
                          return (
                            <div className="row mt-4" key={k}>
                              <div className="gets-number">
                                <h6>(Guest {k + 1})</h6>
                              </div>
                              <div className="col-md-2">
                                <label
                                  htmlFor="inputPassword5"
                                  className="form-label"
                                >
                                  Gender
                                </label>
                                <select
                                  onChange={(e) => changeFiled(e, "gender", k)}
                                  className="form-select"
                                  aria-label="Default select example"
                                >
                                  <option selected="">Gender</option>
                                  <option
                                    selected={
                                      g?.gender === "Male" ? "selected" : false
                                    }
                                    value={"Male"}
                                  >
                                    Male
                                  </option>
                                  <option
                                    selected={
                                      g?.gender === "Female"
                                        ? "selected"
                                        : false
                                    }
                                    value={"Female"}
                                  >
                                    Female
                                  </option>
                                  <option
                                    selected={
                                      g?.gender === "Others"
                                        ? "selected"
                                        : false
                                    }
                                    value={"Others"}
                                  >
                                    Others
                                  </option>
                                </select>
                              </div>
                              <div className="col-md-4">
                                <label
                                  htmlFor="inputPassword5"
                                  className="form-label"
                                >
                                  Full name
                                </label>
                                <input
                                  onChange={(e) => changeFiled(e, "name", k)}
                                  type="text"
                                  id="inputPassword5"
                                  className="form-control"
                                  placeholder="Enter Full Name"
                                  value={g?.name}
                                />
                              </div>
                              <div className="col-md-4">
                                <label
                                  htmlFor="inputPassword5"
                                  className="form-label"
                                >
                                  Age
                                </label>
                                <input
                                  onChange={(e) => changeFiled(e, "age", k)}
                                  type="number"
                                  id="inputPassword5"
                                  className="form-control"
                                  value={g?.age}
                                />
                              </div>
                              {k > 0 && (
                                <button
                                  type="button"
                                  onClick={() => removeGuest(k)}
                                  class="btn btn-danger delete-btn"
                                >
                                  Delete
                                </button>
                              )}
                            </div>
                          );
                        })}
                    </form>
                    <button
                      type="button"
                      onClick={() => addMoreGuest()}
                      className="btn btn-primary"
                    >
                      Add More Guest
                    </button>
                  </div>
                </div>
                <div className="check-box mt-3">
                  <div className="mb-3 form-check">
                    <input
                      onChange={(e) => changeTerms(e)}
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                    <label className="form-check-label" htmlFor="exampleCheck1">
                      Check me out
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="left-side-content">
                  <div className="details-price-box-chackout">
                    <div className="fixed-price">
                      <h5 className="mb-0">Starting from</h5>
                      <hr />
                      <div className="base-price">
                        <span>Base Price :</span>{" "}
                        <h6>
                          <i className="fa fa-inr" /> {packages?.base_price}
                        </h6>
                      </div>
                      <hr />
                      <div className="tax-included">
                        <span>Tax Price :</span>{" "}
                        <h6>
                          <i className="fa fa-inr" /> {Math.round(packages?.tax,2)}
                        </h6>
                      </div>
                      <hr />
                      <div className="Total-price">
                        <span>Total Price :</span>{" "}
                        <h6>
                          <i className="fa fa-inr" /> {packages?.base_price+ Math.round(packages?.tax,2)}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="apply-copen">
                    <div className="apply-text">
                      <h5>Apply Coupons</h5>
                    </div>
                    <div className="apply-input">
                      <input
                        type="text"
                        id="inputPassword5"
                        className="form-control"
                        placeholder="Enter Promo Code"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="continue-btn">
                <button
                  disabled={!checkOut}
                  className="btn btn-continue"
                  type="button"
                  onClick={() => preview()}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <PreviewBooking
        isModalVisible={isModalVisible}
        guestCount={guestCount}
        bookingDetails={bookingDetails}
        confirmBooking={confirmBooking}
        setIsModalVisible={setIsModalVisible}
      />
    </>
  );
}
